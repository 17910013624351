import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ConstantsService {
  constructor() {}

  // VALIDATION SETTINGS
  PHONE = {
    // pattern: /^([0|\+[0-9]{1,5})?([6-9][0-9]{9})$/,
    pattern: /^[6-9]\d{9}$/,
    length: 10,
    otpLength: 6,
  };

  DATE = {
    pattern: /^[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])$/,
  };

  EMAIL_REGEXP =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // USER SETTINGS
  DEFAULT_USER_PROFILE: 'https://globalolympiad.s3.amazonaws.com/static/globalolympiad/assets/images/svg/user-img.svg';

  // SITE SETTINGS
  ALLOWED_IMAGE_FORMATS: ['image/png', 'image/jpeg'];
  CALENDER = [
    {
      name: 'January',
      month: 0,
      totalDays: 31,
    },
    {
      name: 'February',
      month: 1,
      totalDays: 28,
    },
    {
      name: 'March',
      month: 2,
      totalDays: 31,
    },
    {
      name: 'April',
      month: 3,
      totalDays: 30,
    },
    {
      name: 'May',
      month: 4,
      totalDays: 31,
    },
    {
      name: 'June',
      month: 5,
      totalDays: 30,
    },
    {
      name: 'July',
      month: 6,
      totalDays: 31,
    },
    {
      name: 'August',
      month: 7,
      totalDays: 31,
    },
    {
      name: 'September',
      month: 8,
      totalDays: 30,
    },
    {
      name: 'October',
      month: 9,
      totalDays: 31,
    },
    {
      name: 'November',
      month: 10,
      totalDays: 30,
    },
    {
      name: 'December',
      month: 11,
      totalDays: 31,
    },
  ];
  WEEK = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  WEEKSMALL = ['Sun', 'Mon', 'Tues', 'Wednes', 'Thurs', 'Fri', 'Satur'];
  WEEKXSMALL = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  MONTH = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  validateEmailOrPhone(contorRecived: FormControl) {
    let PHONE_REGEXP_PATTERN = /^[6-9]\d{9}$/;
    let errorToThrow = {
      inValidEmail: false,
      inValidNumber: false,
    };
    let EMAIL_REGEXP =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    EMAIL_REGEXP.test(contorRecived.value)
      ? (errorToThrow.inValidEmail = false)
      : (errorToThrow.inValidEmail = true);
    PHONE_REGEXP_PATTERN.test(contorRecived.value)
      ? (errorToThrow.inValidNumber = false)
      : (errorToThrow.inValidNumber = true);
    if (
      (errorToThrow.inValidEmail && !errorToThrow.inValidNumber) ||
      (!errorToThrow.inValidEmail && errorToThrow.inValidNumber)
    ) {
      return null;
    }
    return errorToThrow;
  }

  loginApiUrl = `${environment.BASE_URL}auth/login`;
  logoutApiUrl = `${environment.BASE_URL}auth/logout`;
  signupApiUrl = `${environment.BASE_URL}auth/register`;
  signupTeacherApiUrl = `${environment.BASE_URL}auth/users/request`;
  getUpdateProfileUrl = `${environment.BASE_URL}auth/account`;
  picUpdateUrl = `${environment.BASE_URL}account/edit/profile`;
  forgotPasswordUrl = `${environment.BASE_URL}auth/forgotpassword`;
  resetPasswordUrl = `${environment.BASE_URL}auth/resetpassword`;

  //   admin api
  schoolListUrl = `${environment.BASE_URL}school`;
  addSchoolApiUrl = `${environment.BASE_URL}school/schoolprincipals`;
  validateUserApiUrl = `${environment.BASE_URL}auth/validateuser`;
  subscriptionListApiUrl = `${environment.BASE_URL}subscriptions`;
  searchSchoolApiUrl = `${environment.BASE_URL}school/search`;

  createAdminApiUrl = `${environment.BASE_URL}auth/users`;
  updatePasswordApiUrl = `${environment.BASE_URL}auth/account/edit/password`;
  captureProfile = `${environment.BASE_URL}auth/profile`;
  editProfileApiUrl = `${environment.BASE_URL}auth/account/edit`;
  editProfilePicApiUrl = `${environment.BASE_URL}auth/account/edit/profile`;
  bulkRegisterApiUrl = `${environment.BASE_URL}auth/users/bulkcreate/multigrade`;
  gradeListApiUrl = `${environment.BASE_URL}grade`;
  scheduleQuizApiUrl = `${environment.BASE_URL_CMS}schedule`;
  addQuiz = `${environment.BASE_URL_CMS}quiz`;
  leaderboardApiUl = `${environment.BASE_URL}leaderboard/quiz`;
  sectionsListApiUrl = `${environment.BASE_URL}section`;
  createTopic = `${environment.BASE_URL_CMS}topics`;
  addActivity = `${environment.BASE_URL}activity`;
  createSubject = `${environment.BASE_URL}subjects`;

  messageApiUrl = `${environment.BASE_URL}message`;
  // liveSessionApiUrl = `https://room.aptence.com/bigbluebutton/api`;
  liveSessionApiUrl = `${environment.BASE_URL_CMS}meeting`;
  updateVideoApiUrl = `${environment.BASE_URL_CMS}videos`;
  checkVideoUploadStatusApiUrl = `${environment.BASE_URL_CMS}videos/video-status`;
  updateDocApiUrl = `${environment.BASE_URL_CMS}documents`;
  addGameApiUrl = `${environment.BASE_URL_CMS}games`;
  courseApiUrl = `${environment.BASE_URL_CMS}course`;
  samplePaperApiUrl = `${environment.BASE_URL_CMS}samplepaper`;
  examApiUrl = `${environment.BASE_URL_CMS}exam`;
  tagsApiUrl = `${environment.BASE_URL_CMS}tags`;
  searchJobListingApiUrl = `${environment.BASE_URL_CMS}serpjoblisting`;
  zoomKeyApiUrl = `${environment.BASE_URL}zoom`;
  aptCourseApiUrl = `${environment.BASE_URL_CMS}aptcourse`;
  createExamCategoryApiUrl = `${environment.BASE_URL_CMS}course/category`;
  ExamSubCategoryApiUrl = `${environment.BASE_URL_CMS}course/subcategory`;
  createSubjectQuestionApiUrl = `${environment.BASE_URL_CMS}exam/assesmentsubjectquestion`;
  languageApiUrl = `${environment.BASE_URL_CMS}language`;
  ExamSecondSubCategory = `${environment.BASE_URL_CMS}course/secondsubcategory`;
  uploadCSVFile = `${environment.BASE_URL_CMS}savefile`;
  analyticsApiUrl = `${environment.BASE_URL_CMS}track`;
  meetingTrackApiUrl = `${environment.BASE_URL_CMS}meetingtrack`;
  roleApiUrl = `${environment.BASE_URL}auth/role`;
  teacherRequestApiUrl = `${environment.BASE_URL}auth/users/request`;
  getteacherRequestApiUrl = `${environment.BASE_URL}auth/users/teacherrequest/all`;
  assignteacherRequestApiUrl = `${environment.BASE_URL}auth/users/teacherrequest/assign`;
  fetchuserbyidsApiUrl = `${environment.BASE_URL}auth/users/fetchbyids`;
  cityApiUrl = `${environment.BASE_URL}auth/users/cities`;
  stateApiUrl = `${environment.BASE_URL}auth/users/states`;
  findcourseApiURL = `${environment.BASE_URL_CMS}course/findcourse`;
  megatestseriesApiUrl = `${environment.BASE_URL_CMS}megatestseries`;
  updateMegatestseriesApiUrl = `${environment.BASE_URL_CMS}megatestseries/editMegatestseries`;
  getSingleMegatestseriesApiUrl = `${environment.BASE_URL_CMS}megatestseries/getMegatestseriesDetails`;
  deleteMegatestseriesApiUrl = `${environment.BASE_URL_CMS}megatestseries/deleteMegatestseries`;

  //  user api
  fetchTopicsApiUrl = `${environment.BASE_URL_CMS}topics`;
  fetchSubjectsApiUrl = `${environment.BASE_URL_CMS}subjects`;
  parentsChildApiUrl = `${environment.BASE_URL}parents`;
  childGradeUpdateApiUrl = `${environment.BASE_URL}auth/users/gradesection/update`;
  appointmentApiUrl = `${environment.BASE_URL_CMS}appointment`;
  instructorApiUrl = `${environment.BASE_URL}instructors`;
  subjectApiUrl = `${environment.BASE_URL_CMS}subjects`;
  updateChapterDescription = `${environment.BASE_URL_CMS}course/update-chapter`;
  updateSingleChapterDescription = `${environment.BASE_URL_CMS}course/updatebookdescription`;
  // subscription api
  validateSubscriptionApiUrl = `${environment.BASE_URL_CMS}payment/verify`;

  getSignedurlApiUrl = `${environment.BASE_URL_CMS}videos/signedurl/`;
  getSignedurlBookApiUrl = `${environment.BASE_URL_CMS}course/getbooksignedurl/`;

  //   aspi not created
  sendOptReqUrl = `${environment.BASE_URL}`;
  verifyOptReqUrl = `${environment.BASE_URL}auth/verify`;
  changePasswordUrl = `${environment.BASE_URL}`;
  contactReqUrl = 'https://contactus.aptence.com/contactform/';

  // USER SETTINGS
  LOGIN_EXPIRY_TIME = 7;
  COOKIE_EXPIREY_TIME = 7;

  // Admin
  getSelectedQuestionApiUrl = `${environment.BASE_URL_CMS}exam/assesmentpaper`;

  //user
  getAllLanguagesApiUrl = `${environment.BASE_URL_CMS}exam/languages`;

  // admin
  exportQuestions = `${environment.BASE_URL_CMS}exam/exportSelectedQuestion`;

  // total attempts
  getTotalAttemptsApiUrl = `${environment.BASE_URL_CMS}exam/questionpaperattempts`;

  // all attempts paper reports
  fetchAllAttemptsPaperReport = `${environment.BASE_URL_CMS}exam/fetchallsolutions`;

  // all reported questions
  fetchAllReportedQuestions = `${environment.BASE_URL_CMS}reportquestion/all/reports`;

  // submit reported question
  submitReportedQuestion = `${environment.BASE_URL_CMS}reportquestion`;
}
